<template>
    <Layout class="c-news-home" :flushWithHeader="true">
        <template v-if="newsHomeContent">
            <PageHeader
                v-if="newsHomeContent.header"
                :pageTitle="newsHomeContent.header.editorialText.headline"
                :pageSubTitle="newsHomeContent.header.editorialText.label"
                :backgroundImages="newsHomeContent.header.backgroundImage"
                :textColor="newsHomeContent.header.editorialText.textColor"
                class="c-news-home__page-header"
            />
            <div class="o-row">
                <div class="o-extra-small--12 o-large--10 o-large--offset-1">
                    <template v-if="newsHomeContent.heroArticle">
                        <h4 class="u-spacer--2">
                            {{ newsHomeContent.heroArticleTitle }}
                        </h4>
                        <ArticleCard
                            :isFeatured="true"
                            :articleSummary="newsHomeContent.heroArticle"
                            class="u-spacer--8"
                        />
                        <div class="c-news-home__hr u-spacer--3"></div>
                    </template>
                    <template v-if="!isEmpty(homepageNewsArticles)">
                        <h4 class="u-spacer--2">
                            {{ newsHomeContent.allArticlesTitle }}
                        </h4>
                        <div class="c-news-home__related-articles o-row o-row--nested">
                            <ArticleCard
                                v-for="(article, index) in homepageNewsArticles"
                                :key="`${article.slug}-${index}`"
                                :articleSummary="article"
                                class="c-news-home__related-article o-extra-small--12 o-large--6"
                            />
                        </div>
                        <div class="c-news-home__hr u-spacer--3"></div>
                    </template>
                    <div v-if="pressContent">
                        <h4 class="u-spacer--2">
                            {{ pressContent.title }}
                        </h4>
                        <ContentfulContentBlock
                            :contentRows="pressContent.rows"
                            :contentTitle="newsHomeContent.title"
                        />
                    </div>
                </div>
            </div>
        </template>
    </Layout>
</template>

<script>
import { get, isEmpty } from 'lodash-es';
import { mapGetters, mapState } from 'vuex';

import newsModule, {
    NEWS_MODULE_NAME,
    FETCH_NEWS_HOMEPAGE_CONTENT,
    FETCH_RECENT_NEWS_ARTICLES,
} from '~routes/news/js/news-store';

import analyticsMixin from '~coreModules/analytics/js/analytics';
import newsHomepageMetaMixin from '~routes/news/js/news-homepage-meta';

import Layout from '~modules/core/components/layouts/Layout.vue';

import PageHeader from '~coreModules/core/components/ui/PageHeader.vue';
import ContentfulContentBlock from '~modules/contentful/components/ContentfulContentBlock.vue';
import ArticleCard from '~routes/news/components/ArticleCard.vue';

export default {
    name: 'NewsHomeContainer',
    components: {
        ArticleCard,
        ContentfulContentBlock,
        Layout,
        PageHeader,
    },
    mixins: [
        analyticsMixin,
        newsHomepageMetaMixin,
    ],
    vuex: {
        moduleName: NEWS_MODULE_NAME,
        module: newsModule,
    },
    data() {
        return {
            isEmpty,
        };
    },
    computed: {
        ...mapGetters(NEWS_MODULE_NAME, [
            'homepageNewsArticles',
        ]),
        ...mapState(NEWS_MODULE_NAME, [
            'newsHomeContent',
        ]),
        headerContent() {
            return get(this.newsHomeContent, 'header');
        },
        pressContent() {
            return get(this.newsHomeContent, 'pressContent');
        },
    },
    async fetchData({ store }) {
        const parallelRequests = [
            await store.dispatch(`${NEWS_MODULE_NAME}/${FETCH_NEWS_HOMEPAGE_CONTENT}`),
            await store.dispatch(`${NEWS_MODULE_NAME}/${FETCH_RECENT_NEWS_ARTICLES}`),
        ];

        await Promise.all(parallelRequests);
    },
};
</script>

<style lang="scss">
    .c-news-home {
        &__page-header {
            margin-bottom: $nu-spacer-6;

            @include breakpoint(large) {
                margin-bottom: $nu-spacer-8;
            }
        }

        &__related-articles {
            margin-bottom: $nu-spacer-6;

            @include breakpoint(large) {
                margin-bottom: $nu-spacer-5;
            }
        }

        &__related-article {
            margin-bottom: $nu-spacer-2;

            @include breakpoint(large) {
                margin-bottom: $nu-spacer-3;
            }
        }

        &__hr {
            @include horizontal-rule($nu-gray--medium);
        }
    }
</style>
