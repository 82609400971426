<template>
    <div
        class="c-page-header o-flex-vertical-center o-flex-horizontal-center"
        :class="{
            'c-page-header--no-image': isEmpty(backgroundImage),
            [`c-page-header--${color}`]: !!color,
        }"
        :style="!isEmpty(backgroundImage) && pageHeaderStyle"
    >
        <div class="c-page-header__text-container">
            <p
                v-if="pageSubTitle"
                class="o-text--label u-spacer--1"
                :class="{
                    'u-text--gray': isEmpty(backgroundImage),
                }"
            >
                {{ pageSubTitle }}
            </p>
            <h3
                v-if="pageTitle"
                class="c-page-header__title"
            >
                {{ pageTitle }}
            </h3>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { isEmpty } from 'lodash-es';

import { useState } from '~coreModules/core/js/composables/vuexHelpers';

import { SITE_SETTINGS_MODULE_NAME } from '~coreModules/site-settings/js/site-settings-store';
import { PAGE_HEADER_TEXT_COLORS } from '~coreModules/contentful/js/contentful-constants';

// NU_TODO: https://urbnit.atlassian.net/browse/TYP-26682
// Remove below when we update to vue-eslint-parser v9.0.0 or later
/* eslint-disable vue/valid-define-props */
const props = defineProps({
    pageTitle: {
        type: String,
        default: '',
    },
    pageSubTitle: {
        type: String,
        default: '',
    },
    headerType: {
        type: String,
        default: '',
    },
    backgroundImages: {
        type: Object,
        default: () => {},
    },
    textColor: {
        type: String,
        default: PAGE_HEADER_TEXT_COLORS.black,
        validator: color => Object.values(PAGE_HEADER_TEXT_COLORS).includes(color),
    },
});

const { contentfulAppConfig } = useState(SITE_SETTINGS_MODULE_NAME, ['contentfulAppConfig']);

const backgroundImage = computed(() => {
    if (props.headerType) {
        return contentfulAppConfig.value?.[props.headerType]?.backgroundImage || {};
    }
    return props.backgroundImages;
});
const smallImage = computed(() => backgroundImage.value.smallImage?.url || '');
const largeImage = computed(() => backgroundImage.value.largeImage?.url || '');

/* eslint-disable no-use-before-define */
const smallBackgroundImageUrl = computed(() => {
    if (!smallImage.value) return false;
    return getImageUrl(smallImage.value);
});
const mediumBackgroundImageUrl = computed(() => {
    if (!largeImage.value) return false;
    return getImageUrl(largeImage.value);
});

const pageHeaderStyle = computed(() => ({
    '--small-background-image': smallBackgroundImageUrl.value,
    '--medium-background-image': mediumBackgroundImageUrl.value,
}));
const color = computed(() => (props.headerType ?
    contentfulAppConfig.value?.[props.headerType].editorialText?.textColor : props.textColor));

function getImageUrl(baseUrl) {
    const quality = backgroundImage.value.imageQuality || 90;
    return `url(${baseUrl}?q=${quality})`;
}
</script>

<style lang="scss">
    .c-page-header {
        background-color: $placeholder-background;
        background-size: cover;
        text-align: center;
        background-image: var(--small-background-image);
        height: 84px;
        padding: $nu-spacer-2;

        @include breakpoint(medium) {
            background-image: var(--medium-background-image);
            height: 124px;
            padding: $nu-spacer-4 $nu-spacer-2;
        }

        &--no-image {
            background-color: transparent;
            background-image: none;
        }

        &--black {
            color: $nu-black;
        }

        &--white {
            color: $nu-white;
        }

        &--primary {
            color: $nu-primary;
        }

        &--secondary {
            color: $nu-secondary;
        }

        &__title {
            @include breakpoint(small) {
                @include heading-1;
            }
        }
    }
</style>
